body {
  margin: 0;
  padding: 0;
  font-family: "Clash Display";
  color: #1d1d1f;
  overflow-x: hidden;
}

a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  text-decoration: none;
}

.min {
  min-height: 100vh;
}

.head {
  max-width: 75%;
  margin: 0 auto 20px;
  text-align: center;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* Style the links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown content when the button is hovered over */
.dropdown:hover .dropdown-content {
  display: block;
}

.norecords {
  color: rgba(29, 29, 31, 0.5);
  font-family: Sk-Modernist;
  font-size: 24px;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
}

.page_box {
  padding-top: 110px;
  min-height: 100vh;
}

/* nav onscroll  */
img.brand_logo {
  width: 80px;
  display: none;
}

.desktoplogo {
  width: 80px;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    134deg,
    rgba(207, 207, 207, 0.4) 0%,
    rgba(207, 207, 207, 0.24) 100%
  ) !important;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.mob_view {
  display: none !important;
}

.main_navbar {
  background: linear-gradient(
    134deg,
    rgba(207, 207, 207, 0.4) 0%,
    rgba(207, 207, 207, 0.24) 100%
  );
  backdrop-filter: blur(10.5px);
  z-index: 1000;
}
.whitenav .main_navbar {
  background: linear-gradient(
    134deg,
    rgb(207 207 207) 0%,
    rgb(207 207 207 / 85%) 100%
  );
}

.main_navbar .navbar_common {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.main_navbar .navbar-toggler {
  border: 1px solid rgba(29, 29, 31, 0.8);
  box-shadow: none;
  border-radius: 0;
}

.main_navbar .btn-close {
  box-shadow: none;
}

/* nav right  */
.main_navbar .navbar_right ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.main_navbar .navbar_right ul li {
  margin-right: 25px;
}

.main_navbar .navbar_right ul li a {
  color: #1d1d1f;
  text-decoration: none;
}

.main_navbar .navbar_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 54%;
}

/* navleft */
.main_navbar .nav_left {
  display: flex;
  align-items: center;
}

.main_navbar .nav_left .dropdown .btn {
  padding: 0;
  outline: 0;
  box-shadow: none !important;
  border: 0;
}

.main_navbar .nav_left .dropdown .btn::after {
  background: url("./assets/images/dropdown_arw.svg") no-repeat center;
  border: 0;
  width: 12px;
  height: 7px;
  margin-left: 10px;
  margin-bottom: -5px;
}

.main_navbar .nav_left .dropdown .btn .usr {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.main_navbar .nav_left .dropdown-menu {
  border-radius: 0;
  font-family: Sk-Modernist;
  font-size: 20px;
  border: 1px solid rgba(29, 29, 31, 0.3);
  background: #f7f9fc;
  padding: 0;
  width: 300px;
}

.main_navbar .nav_left .dropdown-item {
  border-bottom: 1px solid rgba(29, 29, 31, 0.3);
  padding: 10px 0;
  text-decoration: none;
  background: transparent !important;
  color: #1d1d1f !important;
}

.main_navbar .nav_left .box {
  padding: 10px;
  border: 1px solid rgba(29, 29, 31, 0.6);
  max-width: calc(100% - 30px);
  margin: 15px auto 0;
}

.main_navbar .nav_left .box .fle_x.bdr {
  border-bottom: 1px solid rgba(29, 29, 31, 0.3);
  padding-bottom: 8px;
  margin-bottom: 10px;
}

.main_navbar .nav_left .box .fle_x {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main_navbar .nav_left .box .fle_x p {
  color: #060a23;
  font-family: Sk-Modernist;
  font-size: 20px;
  margin-bottom: 0;
}

.main_navbar .nav_left .box .fle_x .dollar {
  color: rgba(29, 29, 31, 0.7);
}

.main_navbar .nav_left .box .fle_x .connected {
  border-radius: 50px;
  border: 0.3px solid #027a48;
  background: #ecfdf3;
  color: #027a48;
  font-family: Sk-Modernist;
  font-size: 9px;
  padding: 0 5px;
}

.main_navbar .nav_left .box .fle_x img {
  height: 13px;
}
.main_navbar .nav_left .box .fle_x .cpy {
  height: unset;
  cursor: pointer;
}
.main_navbar .nav_left .box .fle_x .d-flex {
  align-items: center;
}

.main_navbar .nav_left .box .fle_x span {
  color: rgba(29, 29, 31, 0.7);
  font-family: Sk-Modernist;
  font-size: 13px;
}

.main_navbar .nav_left .blw_box {
  padding: 20px;
}

.main_navbar .nav_left .notification .btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid #060a23;
}

.main_navbar .nav_left .notification .btn::after {
  display: none;
}

.main_navbar .nav_left .notification h5 {
  color: #060a23;
  font-family: Clash Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 15px;
}

.main_navbar .nav_left .notification .dropdown-menu {
  padding: 20px 25px;
}

.main_navbar .nav_left .notification a {
  word-break: break-word;
  white-space: unset;
  color: rgba(29, 29, 31, 0.7) !important;
  font-family: Sk-Modernist;
  font-size: 14px;
}

.main_navbar .nav_left .wallet_addr {
  display: flex;
  align-items: center;
  border: 1px solid #060a23;
}

.main_navbar .nav_left .wallet_addr a {
  border-left: 1px solid #060a23;
  border-right: 1px solid #060a23;
  padding: 8px 15px;
}

.main_navbar .nav_left .wallet_addr span {
  padding: 0 8px;
}

.main_navbar .nav_left .wallet_addr span img {
  height: 18px;
}

.main_navbar .nav_left .wallet_addr .copy {
  cursor: pointer;
}

.main_navbar .nav_left .dropdown-menu[data-bs-popper] {
  left: -200px;
}

.main_navbar .profile_mob .box {
  max-width: 100%;
}

.main_navbar .profile_mob li {
  list-style-type: none;
}

.main_navbar .profile_mob li a {
  color: #1d1d1f;
  font-size: 18px;
  font-family: Sk-Modernist;
  text-align: center;
  border: 0 !important;
}

.main_navbar .input-group {
  width: unset;
  z-index: 1;
  flex-wrap: nowrap;
}

.main_navbar .search_mob {
  display: none;
}

.main_navbar .search .input-group-text {
  border-radius: 100px 0 0 100px;
  background: #f7f9fc;
  border: 0;
  cursor: pointer;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 100px !important;
}

.main_navbar .search:hover .input-group-text {
  border-radius: 100px 0 0 100px !important;
  border: 1px solid transparent;
}

.main_navbar .search:hover .input-group-text img {
  filter: unset;
}

.main_navbar .search input {
  border-radius: 0 100px 100px 0;
  background: #f7f9fc !important;
  border: 0;
  box-shadow: none;
  padding-right: 20px;
  transition: 1s;

  padding: 0;
  width: 20px;
  margin-left: -20px !important;
  z-index: -1 !important;
}

.main_navbar .search:hover input {
  width: 290px;
  padding-left: 20px !important;
}

.main_navbar .search input::placeholder {
  color: #808080;
  font-family: Sk-Modernist;
  padding-left: 0px;
}

.main_navbar .primary_btn {
  font-family: Sk-Modernist;
  font-size: 18px;
  padding: 10px 15px;
  text-decoration: none;
}

@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/font/ClashDisplay-Variable.woff2") format("woff2"),
    url("../src/assets/font/ClashDisplay-Variable.woff") format("woff"),
    url("../src/assets/font/ClashDisplay-Variable.ttf") format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/font/ClashDisplay-Extralight.woff2") format("woff2"),
    url("../src/assets/font/ClashDisplay-Extralight.woff") format("woff"),
    url("../src/assets/font/ClashDisplay-Extralight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/font/ClashDisplay-Light.woff2") format("woff2"),
    url("../src/assets/font/ClashDisplay-Light.woff") format("woff"),
    url("../src/assets/font/ClashDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/font/ClashDisplay-Regular.woff2") format("woff2"),
    url("../src/assets/font/ClashDisplay-Regular.woff") format("woff"),
    url("../src/assets/font/ClashDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/font/ClashDisplay-Medium.woff2") format("woff2"),
    url("../src/assets/font/ClashDisplay-Medium.woff") format("woff"),
    url("../src/assets/font/ClashDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/font/ClashDisplay-Semibold.woff2") format("woff2"),
    url("../src/assets/font/ClashDisplay-Semibold.woff") format("woff"),
    url("../src/assets/font/ClashDisplay-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Clash Display";
  src: url("../src/assets/font/ClashDisplay-Bold.woff2") format("woff2"),
    url("../src/assets/font/ClashDisplay-Bold.woff") format("woff"),
    url("../src/assets/font/ClashDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Sk-Modernist";
  src: url("../src/assets/font/sk-modernist-bold-webfont.woff2") format("woff2"),
    url("../src/assets/font/sk-modernist-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sk-Modernist";
  src: url("../src/assets/font/sk-modernist-mono-webfont.woff2") format("woff2"),
    url("../src/assets/font/sk-modernist-mono-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sk-Modernist";
  src: url("../src/assets/font/sk-modernist-regular-webfont.woff2")
      format("woff2"),
    url("../src/assets/font/sk-modernist-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.h2tag {
  color: #060a23;
  font-family: "Clash Display";
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 30px;
}

.para {
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 20px;
}

.primary_btn {
  font-family: "Sk-Modernist";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  border: 1px solid #060a23;
  box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.04);
  background: transparent;
  padding: 10px 25px;
  color: #060a23;
  transition: 0.5s;
}

.primary_btn:hover {
  background: #0b081b;
  color: #fff !important;
}

.primary_btn:hover img {
  filter: invert(1);
}

.primary_btn.dark {
  background: #0b081b;
  color: #fff;
}

.primary_btn.dark:hover {
  background: transparent;
  border: 1px solid #060a23;
  color: #060a23 !important;
}
.primary_btn.dark:hover img {
  filter: invert(1) brightness(0);
}
.primary_btn.red {
  border: 1px solid #b90000;
  background: #ffd9d9;
  color: #b90000;
  font-size: 18px;
}

.primary_btn.red:hover {
  background: transparent;
  border: 1px solid #060a23;
  color: #060a23 !important;
}
.primary_btn.red:hover img {
  filter: invert(1) brightness(0);
}

section {
  padding: 55px 0;
}

.page_header {
  background: url("./assets/images/banbg.png") no-repeat center;
  padding: 80px 0;
  color: #fff;
  background-size: 100% 100%;
}
.page_header .row {
  min-height: calc(100vh - 200px);
}
.page_header h1 {
  font-size: 68px;
  font-weight: 500;
  font-family: "Clash Display";
}

.page_header p {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 35px;
}

.page_header .primary_btn {
  color: #fff;
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.04);
  transition: 0.5s;
}

.page_header .primary_btn:hover {
  background: #e233db;
  border: 1px solid #e233db;
}

.page_header .primary_btn:hover img {
  filter: invert(0);
}

.page_header .img_box {
  text-align: center;
}

.page_header .img_box img {
  max-width: 60%;
}

.popular_nft {
  background: #f7f9fc;
}

.popular_nft .box {
  background: #fff;
  border: 1px solid #1d1d1f;
}

.popular_nft .box .img_box img {
  height: 414px;
  width: 100%;
  /* object-fit: cover; */
}

.popular_nft .blw_box {
  padding: 20px 25px;
}

.popular_nft .blw_box h5 {
  color: #1d1d1f;
  font-family: Clash Display;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 20px;
}

.popular_nft .box .flx_ctn > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popular_nft .box span {
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 18px;
}

.popular_nft .box .primary_btn:hover span {
  color: #fff;
}

.popular_nft .box p,
.popular_nft .box h6 {
  color: #060a23;
  font-family: Clash Display;
  font-size: 24px;
  margin-bottom: 0;
  line-height: 24px;
}

.popular_nft .box h6 {
  font-weight: 500;
}

.popular_nft .primary_btn {
  margin-top: 30px;
}

.recent_swap table {
  vertical-align: middle;
  color: #060a23;
  font-family: Clash Display;
  font-size: 18px;
  font-weight: 500;
  border: 0px solid rgba(0, 0, 0, 0.24);
  max-width: 99.95%;
}

.recent_swap table th {
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 18px;
  font-weight: 400;
  border: 1px solid transparent;
  background: transparent;
  white-space: nowrap;
  padding-bottom: 30px;
}

.recent_swap table th:nth-child(1) {
  padding-left: 20px;
}

.recent_swap table td {
  border: 0;
  background: #fff;
  white-space: nowrap;
}

.recent_swap table td.red {
  color: #ef1414;
}

.recent_swap table td.green {
  color: #14ef2a;
}

.recent_swap table td .flx {
  display: flex;
  align-items: center;
}

.recent_swap table td .user_mini {
  border-radius: 14px;
  width: 40px;
  height: 40px;
  margin-right: 13px;
}

.recent_swap table td:nth-child(1) {
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 16px;
  font-weight: 400;
  padding-left: 20px;
}

.recent_swap table td:nth-child(2),
.recent_swap table th:nth-child(2) {
  padding-right: 45px;
}

.recent_swap table td:nth-child(3),
.recent_swap table th:nth-child(3) {
  padding-right: 20px;
}

.recent_swap table th:nth-last-child(1),
.recent_swap table td:nth-last-child(1) {
  padding-right: 50px;
}

.recent_swap table tr:nth-child(1) td {
  padding-top: 35px;
}

.recent_swap table tr:nth-last-child(1) td {
  padding-bottom: 35px;
}

.recent_swap table tr:nth-child(1) td:nth-child(1) {
  border-top-left-radius: 15px;
}

.recent_swap table tr:nth-last-child(1) td:nth-child(1) {
  border-bottom-left-radius: 15px;
}

.recent_swap table tr:nth-child(1) td:nth-last-child(1) {
  border-top-right-radius: 15px;
}

.recent_swap table tr:nth-last-child(1) td:nth-last-child(1) {
  border-bottom-right-radius: 15px;
}

.recent_swap table td:not(:nth-child(1), :nth-child(2)),
.recent_swap table th:not(:nth-child(1), :nth-child(2)) {
  text-align: right;
}

.recent_swap tbody {
  position: relative;
}

.recent_swap tbody::before {
  content: "";
  position: absolute;
  width: calc(100% + 2px);
  background: rgba(0, 0, 0, 0.24);
  height: calc(100% + 2px);
  z-index: -1;
  padding: 10px;
  top: 1px;
  left: -1px;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 15px;
}

.recent_swap .user {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  margin-right: 10px;
  border: 1px solid rgba(68, 68, 68, 0.219);
}

.recent_swap .bdr {
  border-radius: 16px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  padding: 30px 40px;
  width: fit-content;
}

.recent_swap .boxes .rw_flx {
  display: inline-flex;
  align-items: center;
  color: #060a23;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.recent_swap .boxes .rw_flx:nth-last-child(1) {
  margin-bottom: 0;
}

.recent_swap .boxes .rw_flx.head_flx {
  padding: 0 40px;
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 18px;
  font-weight: 400;
}

.recent_swap .rw_flx div:nth-child(1) {
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 16px;
  font-weight: 400;
  width: 30px;
}

.recent_swap .rw_flx div:nth-child(2) {
  width: 200px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.recent_swap .rw_flx div:nth-child(3) {
  width: 220px;
  white-space: nowrap;
}

.recent_swap .rw_flx div:nth-child(4) {
  width: 150px;
}

.recent_swap .rw_flx div:nth-child(5) {
  width: 200px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.recent_swap .rw_flx div:nth-child(6) {
  width: 220px;
  white-space: nowrap;
}

.recent_swap .rw_flx div {
  margin-right: 30px;
}

.recent_swap button {
  border-radius: 100px;
  width: 144px;
  padding: 10px 30px;
  font-family: Sk-Modernist;
  font-size: 16px;
  font-weight: 400;
}

.recent_swap .pending {
  background: #fef6e7;
  color: #f9aa2c;
  border: 1px solid #f9aa2c;
}

.recent_swap .completed {
  background: #ecfdf3;
  border: 1px solid #027a48;
  color: #027a48;
}

.recent_swap_t table td:not(:nth-child(1), :nth-child(2)),
.recent_swap_t table th:not(:nth-child(1), :nth-child(2)) {
  text-align: left;
}

.recent_swap_t table td:nth-child(3),
.recent_swap_t table th:nth-child(3) {
  padding-left: 20px;
}

.recent_swap_t table td:nth-last-child(1),
.recent_swap_t table th:nth-last-child(1) {
  padding-left: 20px;
}

.trending .user_flx {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.trending .user_flx .user {
  display: flex;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #1d1d1f;
  padding: 10px 70px 10px 20px;
  white-space: nowrap;
  color: #060a23;
  font-family: Sk-Modernist;
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;
}
.trending .user_flx a:not(:nth-last-child(1)) {
  margin-right: 15px;
}

.trending .user_flx .user img {
  margin-right: 10px;
  height: 45px;
  border-radius: 50%;
  width: 45px;
  object-fit: fill;
}

.trending .box .img_box img {
  height: 270px;
  width: 100%;
  object-fit: fill;
}

.trending .box p {
  font-size: 20px;
  line-height: unset;
}

.trending .blw_box {
  padding: 20px 20px;
}

.slide .slidebg_out {
  position: relative;
}

.slide .slidebg_out::before {
  content: "";
  background: url("../src/assets/images/slideleft_bg.png") no-repeat center;
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  background-size: cover;
}

.slide .slidebg_out::after {
  content: "";
  background: url("../src/assets/images/slideright_bg.png") no-repeat center;
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-size: cover;
}

.slide .slidebg {
  max-width: 70%;
  margin: 0 auto;
  background-color: #fff;
  padding: 0 15px;
  z-index: 1;
  position: relative;
}

.slide .box {
  padding: 35px 20px;
  padding-right: 40px;
  background: url("../src/assets/images/slideimg.png") no-repeat center;
  background-size: 100% 100%;
}

.slide .box h3 {
  color: #fff;
  font-family: Clash Display;
  font-size: 40px;
  font-weight: 500;
  max-width: 75%;
}

.slide .box .flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 200px; */
}
.event-content {
  margin-bottom: 180px;
  font-size: 16px !important;
  color: #f9f9f9;
}
.slide .box p {
  color: #fff;
  font-family: Sk-Modernist;
  font-size: 20px;
}

.slide .box button {
  border-radius: 100px;
  border: 1px solid #fff;
  background: radial-gradient(
      70.71% 70.71% at 50% 50%,
      rgba(255, 255, 255, 0.02) 0%,
      rgba(6, 10, 35, 0) 21%
    ),
    radial-gradient(
      70.71% 70.71% at 50% 50%,
      rgba(255, 255, 255, 0.02) 0%,
      rgba(6, 10, 35, 0) 19%
    ),
    radial-gradient(
      70.71% 70.71% at 50% 50%,
      rgba(6, 10, 35, 0.05) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(6, 10, 35, 0.3);
  box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.04);
  padding: 10px 40px;
  color: #fff;
  font-family: Sk-Modernist;
  font-size: 18px;
}

.slide .slick-prev,
.slide .slick-next {
  top: 45%;
}

.slide .slick-prev:before {
  background: url("../src/assets/images/leftarw.svg");
  font-size: 0;
  width: 80px;
  height: 80px;
  display: block;
  left: -198px;
  position: absolute;
}

.slide .slick-next:before {
  background: url("../src/assets/images/rightarw.svg");
  font-size: 0;
  width: 80px;
  height: 80px;
  display: block;
  right: -198px;
  position: absolute;
}

.slidechg {
  overflow: hidden;
}
.slidechg .box {
  position: relative;
  overflow: hidden;
  background: none;
  padding: 0 !important;
  z-index: unset;
}
.slidechg .slidebg_out::before,
.slidechg .slidebg_out::after {
  display: none;
}
.slidechg .box::before {
  content: "";
  background: url("../src/assets/images/slideleft_bg.png") no-repeat center;
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  background-size: cover;
}

.slidechg .box::after {
  content: "";
  background: url("../src/assets/images/slideright_bg.png") no-repeat center;
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-size: cover;
}
.slidechg .box > div {
  padding: 35px 20px;
  padding-right: 40px;
  background: url("../src/assets/images/slideimg.png") no-repeat center;
  background-size: 100% 100%;
  z-index: 2;
  max-width: 60%;
  position: relative;
  margin: 0 auto;
  border: 15px solid #fff;
  border-top: 0;
  border-bottom: 0;
}
.slidechg .slick-next:before {
  right: 100px !important;
}
.slidechg .slick-prev:before {
  left: 100px !important;
}

.slidechg .slidebg {
  padding: 0;
  max-width: 100%;
}
.slidechg .slick-prev,
.slidechg .slick-next {
  z-index: 100;
  top: 45% !important;
  width: 0;
  height: 0;
}
.slidechg .slick-next:before {
  right: 100px;
}
.footer {
  background: url("../src/assets/images/banbg.png") no-repeat center;
  color: #fff;
  font-family: Sk-Modernist;
  font-size: 24px;
  padding: 60px 0 50px;
  background-size: 100% 100%;
}

.footer .footer_panel {
  padding-bottom: 80px;
  border-bottom: 1px solid #fff;
}

.footer .footer_panel .para {
  color: #fff;
}

.footer .maxbox {
  max-width: 70%;
}

.footer input {
  background: #eaecef;
  border: 0;
  border-radius: 0;
  margin-right: 15px;
  box-shadow: none !important;
  outline: 0 !important;
}

.footer input::placeholder {
  color: #808080;
  font-family: Sk-Modernist;
  font-size: 16px;
}

.footer .input-group button {
  border: 1px solid #fff;
  box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.04);
  color: #fff;
  font-family: Sk-Modernist;
  font-size: 18px;
  border-radius: 0;
}
/* 
.footer .input-group .primary_btn:hover {
  background: #e233db;
  border: 1px solid #e233db;
} */

.footer .input-group button:hover img {
  filter: unset;
}

.footer h6 {
  font-size: 18px;
}

.footer ul {
  padding: 0;
  list-style-type: none;
}

.footer ul li {
  font-family: Clash Display;
  cursor: pointer;
}

.footer .footer_bottom {
  font-family: Sk-Modernist;
  font-size: 20px;
  padding-top: 40px;
}

.footer .footer_bottom ul {
  display: flex;
  justify-content: center;
}

.footer .footer_bottom li {
  margin: 0 35px;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.create_box {
  padding-bottom: 220px;
}

.create_box .para a {
  color: #1d1d1f;
  text-decoration: underline;
}

.create_box .flx {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.create_box .flx_box {
  margin: 0 40px;
}

.create_box .form-check input {
  position: absolute;
  right: 10px;
  top: 10px;
  box-shadow: none;
  width: 20px;
  height: 20px;
  border: 1px solid #000;
}

.create_box .form-check-input:checked {
  background-color: #0b081b;
}

.create_box .form-check-input[type="radio"]:checked {
  border-color: #ffffff;
  background-image: none;
}

.create_box .form-check-input[type="radio"]:checked + label,
.create_box .form-check-input[type="radio"]:hover + label {
  background: #0b081b;
  color: #fff;
}

.create_box .form-check-input[type="radio"]:checked + label p,
.create_box .form-check-input[type="radio"]:hover + label p {
  color: #fff;
}

.create_box .form-check-input[type="radio"]:hover {
  border-color: #fff7f7;
  background: transparent;
}

.create_box .form-check {
  padding: 0;
  position: relative;
}

.create_box label {
  border: 1px solid #1d1d1f;
  background: #f7f9fc;
  width: 400px;
  height: 255px;
  color: #060a23;
  font-family: Clash Display;
  font-size: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  text-align: center;
}

.create_box label img {
  height: 42px;
}

.create_box label p {
  font-size: 16px;
  color: #1d1d1f;
}

.create_box label .best {
  border-radius: 20px;
  background: #9e4200;
  text-align: center;
  padding: 3px 12px 5px 12px;
  color: #fff;
  font-family: Sk-Modernist;
  font-size: 12px;
  position: absolute;
  left: 10px;
  top: 20px;
}

/* progress  */
.cont {
  height: 100%;
  left: calc(50% - 350px);
  padding: 0;
  margin: 40px auto;
  position: absolute;
}

#nprogress-bar {
  -webkit-appearance: none;
  width: 700px;
  color: #ccc;
  height: 2px;
  margin: 0 auto;
}

#first,
#second,
#third,
#fourth {
  margin: 0px;
}

#step span {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  border: 3px solid #ccc;
  background: #cccccc;
  position: absolute;
  left: 0;
  color: #ffffff;
  top: -30px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

#step {
  height: 100px;
  width: 100%;
}

#step span i {
  padding: 20px;
  text-align: center;
  font-size: 40px;
}

#step .first {
  left: -23px;
}

#step .second {
  left: 200px;
}

#step .third {
  left: 420px;
}

#step .fourth {
  left: 640px;
}

.detail {
  position: relative;
}

#nprogress-bar::-webkit-progress-value {
  /* Changes line color */
  background: #049dfe;
  transition: all 0.4s ease-in-out;
}

#nprogress-bar::-webkit-progress-bar {
  /* Changes background color */
  background: #ccc;
}

#step .border-change {
  border-color: #0077c2;
  background: #049dfe;
  transition: all 0.4s ease-in-out;
}

/* progress ends */

.create_nft {
  padding-bottom: 220px;
  font-family: Sk-Modernist;
}

.create_nft p {
  margin-bottom: 0;
}

.create_nft .choose_box {
  border: 1px solid rgba(29, 29, 31, 0.3);
  background: #f7f9fc;
  width: 100%;
  height: 212px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.create_nft .choose_box input {
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  opacity: 0;
}

.create_nft .choose_box span {
  color: rgba(0, 0, 0, 0.5);
  font-family: Sk-Modernist;
  border-radius: 35px;
  background: #d9d9d9;
  padding: 7px 13px;
}

.create_nft label {
  font-family: Sk-Modernist;
  font-size: 24px;
  color: #060a23;
}

.create_nft span.star {
  color: red;
  margin-left: 2px;
}

.create_nft span.small {
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 16px;
}

.create_nft .form-control {
  border: 1px solid rgba(29, 29, 31, 0.3) !important;
  background: #f7f9fc;
  border-radius: 0;
  box-shadow: none !important;
  height: 55px;
}

.create_nft .input_textbox {
  position: relative;
}

.create_nft .input_text {
  position: absolute;
  top: 27%;
  left: 10px;
}

.create_nft .input_textbox input {
  padding-left: 317px;
  font-size: 14px;
}

.create_nft .input-group input {
  border-right: 0 !important;
}

.create_nft .input-group-text {
  background: #f7f9fc;
  border-radius: 0;
  border: 1px solid rgba(29, 29, 31, 0.3) !important;
  border-left: 0 !important;
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 16px;
}

.create_nft textarea {
  border: 1px solid rgba(29, 29, 31, 0.3) !important;
  background: #f7f9fc;
  border-radius: 0;
  box-shadow: none !important;
  resize: none;
  height: 150px !important;
  width: 100%;
  padding: 10px;
}
.create_nft textarea:focus-visible {
  border: 1px solid rgba(29, 29, 31, 0.3) !important;
  outline: 0;
}
.create_nft ::placeholder {
  color: rgba(29, 29, 31, 0.5);
  font-family: Sk-Modernist;
}

.create_nft .form-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #1d1d1f;
  height: 55px;
  padding: 20px 24px !important;
  padding-right: 60px !important;
  position: relative;
}

.create_nft .form-switch p {
  color: var(--secondary-color, #1d1d1f);
  font-family: Sk-Modernist;
  font-size: 19px;
}

.create_nft .form-switch input {
  position: absolute;
  right: 20px;
  cursor: pointer;
}

.create_nft .form-check-input {
  box-shadow: none !important;
  border-color: #000;
  box-shadow: none !important;
}

.create_nft .form-check-input:checked {
  background-color: #000000;
  border-color: #000000;
  box-shadow: none !important;
}

.create_nft .form-check-input[type="radio"]:checked {
  border-color: #ffffff;
  background-image: none;
}

.create_nft .form-check-input[type="radio"]:checked + label,
.create_nft .form-check-input[type="radio"]:hover + label {
  background: #0b081b;
  color: #fff;
}

.create_nft .form-check-input[type="radio"]:checked + label img,
.create_nft .form-check-input[type="radio"]:hover + label img {
  filter: invert(1);
}

.create_nft .form-check-input[type="radio"]:hover {
  border-color: #fff7f7;
  background: transparent;
}

.create_nft .create_nftbox .flx {
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_nft .create_nftbox .flx_box {
  margin: 0 20px;
  width: 100%;
}

.create_nft .create_nftbox .form-check input {
  position: absolute;
  right: 10px;
  top: 10px;
  box-shadow: none;
  width: 20px;
  height: 20px;
}

.create_nft .create_nftbox .form-check {
  padding: 0;
  position: relative;
}

.create_nft .create_nftbox label {
  border: 1px solid rgba(0, 0, 0, 0.8);
  background: #ffffff;
  min-width: 100%;
  height: 205px;
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  text-align: center;
}

.create_nft .create_nftbox label img {
  height: 42px;
}

.create_nft .create_nftbox label p {
  font-size: 16px;
  color: #1d1d1f;
}

.create_nft .create_nftbox label .best {
  border-radius: 20px;
  background: #9e4200;
  text-align: center;
  padding: 3px 12px 5px 12px;
  color: #fff;
  font-family: Sk-Modernist;
  font-size: 12px;
  position: absolute;
  left: 10px;
  top: 20px;
}

.create_nft .price_box {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.price_box {
  padding: 15px;
  border: 1px solid rgba(0, 0, 0, 0.8);
}

.create_nft .price_box .fl_x {
  display: flex;
  justify-content: space-between;
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 16px;
  margin-bottom: 8px;
}

.price_box .fl_x {
  display: flex;
  justify-content: space-between;
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 16px;
  margin-bottom: 8px;
}

.create_nft .price_box .fl_x span:nth-child(2) {
  color: rgba(29, 29, 31, 0.5);
}

.create_nft .price_box .fl_x .dark {
  color: #1d1d1f !important;
}

.create_nft .price_box .fl_x.bdr {
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.create_nft .prv_box {
  border: 1px solid rgba(0, 0, 0, 0.8);
  height: 500px;
  padding: 15px;
}

.create_nft .prv_box img {
  height: 470px;
  width: 100%;
}

.create_collection .choose_box span.dark {
  background: #9e4200;
  color: #fff;
}

.connect_wallet .leftimg {
  min-height: 100vh;
  width: 100%;
}

.connect_wallet .center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: calc(100vh - 54px);
}

.connect_wallet .para a {
  text-decoration: underline;
  color: #1d1d1f;
}

.connect_wallet .connect_wallet_button {
  border: 1px solid rgba(6, 10, 35, 0.4);
  background-color: transparent;
  color: #060a23;
  font-family: Clash Display;
  font-size: 20px;
  padding: 12px 12px 12px 27px;
  outline: 0;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
}

.connect_wallet .connect_wallet_button:hover {
  border-color: rgba(6, 10, 35, 0.4);
}

.connect_wallet .connect_wallet_button.flx {
  justify-content: space-between;
}

.connect_wallet .connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet .connect_wallet_button span {
  color: #060a23;
  font-size: 20px;
  font-weight: 500;
}

.connect_wallet .connect_wallet_button p {
  color: #808080;
  font-family: Sk-Modernist;
  margin-bottom: 0;
}

.connect_wallet h5 {
  color: #060a23;
  font-size: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid #e6e1e5;
}

.connect_wallet a {
  text-decoration: none;
  width: 100%;
}

.wizard-progress {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;
  border-top: 1px solid #060a23;
  padding-top: 60px;
  padding-bottom: 60px;
  position: fixed;
  bottom: -200px;
  z-index: 20;
  background: white;
  height: 300px;
}

.wizard-progress .step {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  overflow: visible;
  position: relative;
  color: rgba(0, 0, 0, 0.8);
  font-family: Sk-Modernist;
  font-size: 20px;
}

.wizard-progress .step:not(:last-child):before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: -24px;
  background-color: #d9d9d9;
  height: 5px;
  width: 99%;
  z-index: 1;
}

.wizard-progress .step:not(:last-child):after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: -24px;
  background-color: #d9d9d9;
  height: 5px;
  width: 100%;
}

.wizard-progress .step .node {
  display: inline-block;
  border: 6px solid #d9d9d9;
  background-color: #d9d9d9;
  border-radius: 18px;
  height: 18px;
  width: 18px;
  position: absolute;
  top: -31px;
  left: 52%;
  z-index: 1;
  margin-left: -18px;
}

.wizard-progress .step.inpage .node {
  border-color: #000;
  background-color: #000;
}

.wizard-progress .step.complete:before {
  background-color: #000;
}

.wizard-progress .step.complete .node {
  border-color: #000;
  background-color: #000;
}

.wizard-progress .step.in_progress:not(:last-child):after {
  background: linear-gradient(90deg, black 20%, transparent 20%);
  z-index: 1;
}

.explore .nav {
  border-color: #e6e1e5;
}

.explore .nav-link {
  border: 0 !important;
  font-size: 20px;
  color: #1d1d1f;
}

.explore .nav-link.active,
.explore .nav-link:hover {
  border-bottom: 1px solid #000 !important;
  color: #1d1d1f;
}

.explore .nav-link.active {
  font-weight: 500;
}

.explore .popular_nft .box .img_box img,
.explore .popular_nft .box .img_box video {
  height: 300px;
}
.explore .popular_nft .box .img_box video {
  object-fit: cover;
}
.explore .popular_nft .box {
  margin-bottom: 40px;
}

.explore .box .blw_ctn {
  padding: 20px 25px;
  padding-top: 15px;
}

.explore .box .blw_ctn h6 {
  font-size: 22px;
  margin-bottom: 15px;
}

.explore .blw_ctn .prf {
  width: 52px;
  height: 52px;
  border-radius: 14px;
  margin-top: -63px;
}

.explore .popular_nftuser .blw_ctn p {
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 16px;
}

.explore .popular_nftuser .blw_ctnbox {
  border-top: 1px solid #060a23;
  padding: 15px;
  text-align: center;
}

.explore .popular_nftuser .blw_ctnbox span {
  color: #060a23;
  font-family: Sk-Modernist;
  font-size: 18px;
}

.explore .popular_nftuser .box .img_box img {
  height: 110px !important;
}

.explore .popular_nftuser .col-lg-3 {
  width: 20%;
}

.explore .h_option {
  display: flex;
  align-items: center;
  margin-top: 45px;
}

.explore .h_option .flx {
  display: flex;
  align-items: center;
}

.explore .h_option .sel {
  position: relative;
  margin-right: 10px;
}

.explore .h_option .sel select {
  border: 1px solid #060a23;
  box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.04);
  color: #060a23;
  font-family: Sk-Modernist;
  font-size: 18px;
  padding: 7px 13px;
  padding-right: 40px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  height: 42px;
}

.explore .h_option .sel::before {
  content: "";
  position: absolute;
  background: url("../src/assets/images/select_down_arw.svg");
  width: 12px;
  height: 7px;
  right: 10px;
  top: 20px;
}

.explore .h_option .icon {
  background: #060a23;
  box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 42px;
  padding: 8px 15px;
  border: 1px solid #060a23;
}
.explore .h_option .icon:hover {
  background: transparent;
  transition: 0.2s;
}
.explore .h_option .icon:hover img {
  filter: invert(1);
}
.explore .h_option .icon img {
  height: 20px;
}

.explore .h_option .sr_ch {
  margin: 0 45px;
}

.explore .h_option .sr_ch .input-group-text {
  border-radius: 100px 0 0 100px !important;
  border: 1px solid transparent;
  cursor: pointer;
  padding: 10px;
  background: #eaecef;
}

.explore .h_option .sr_ch input {
  padding: 10px;
  margin-left: -10px !important;
  border-radius: 0 100px 100px 0;
  background: #eaecef;
  border: 0;
  box-shadow: none;
  padding-right: 20px;
  transition: 1s;
  z-index: 0 !important;
}

.explore .h_option .sr_ch input::placeholder {
  color: #808080;
  font-family: Sk-Modernist;
}

.explore .h_option ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  border: 1px solid #060a23;
}

.explore .h_option ul li {
  padding: 8px 12px;
  color: #060a23;
  font-family: Sk-Modernist;
  cursor: pointer;
  border-right: 1px solid #060a23;
}

.explore .h_option ul li:nth-last-child(1) {
  border-right: 0;
}

.explore .h_option ul li.active {
  background: #060a23;
  color: #fff;
}

.prf_page {
  padding: 0;
  margin-top: -65px;
}

.prf_ban a {
  text-decoration: none;
}

.prf_ban .prf_banimg {
  height: 421px;
  width: 100%;
}

.prf_ban .prf_ban_user {
  margin-bottom: 30px;
}

.prf_ban .prf_ban_user img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  border: 10px solid #fff;
  margin-top: -100px;
}

.prf_ban .btn_flx {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.prf_ban .btn_flx button {
  width: 157px;
  color: #060a23;
  font-family: Sk-Modernist;
  font-size: 18px;
  border: 1px solid #060a23;
  margin-right: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.prf_ban .btn_flx .addr {
  width: unset;
  background: #f7f9fc;
  border: 1px solid rgba(29, 29, 31, 0.3);
}

.prf_ban .btn_flx .addr:hover {
  color: #060a23 !important;
}

.prf_ban .btn_flx .addr:hover img {
  filter: unset;
}

.prf_ban .btn_flx .dropdown button {
  width: unset;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
}

.prf_ban .btn_flx .dropdown button::after {
  display: none;
}
.prf_ban .btn_flx .dropdown-menu a {
  background: #fff !important;
  color: #1d1d1f !important;
}

.prf_ban .social_mob {
  display: none !important;
}

.prf_ban .social {
  display: flex;
  align-items: center;
  padding: 0;
  margin-bottom: 20px;
  list-style-type: none;
}

.prf_ban .social a {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border: 1.144px solid #060a23;
  border-radius: 50%;
  margin-right: 15px;
  text-decoration: none;
}
.prf_ban .social a:hover {
  background: #060a23;
  transition: 0.2s;
}
.prf_ban .social a:hover img {
  filter: invert(1);
}
.prf_ban .box_flx {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
}

.prf_ban .box_flx .box::before {
  content: "";
  position: absolute;
  background: rgba(29, 29, 31, 0.2);
  width: 1px;
  height: 70%;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.prf_ban .box_flx .box:nth-last-child(1):before {
  display: none;
}

.prf_ban .box_flx .box {
  position: relative;
  padding-right: 20px;
}

.prf_ban .box_flx .box:not(:nth-child(1)) {
  padding-left: 20px;
}

.prf_ban .box_flx .box:nth-last-child(1) {
  padding-right: 0;
  padding-left: 20px;
}

.prf_ban .box_flx p {
  margin-bottom: 5px;
}

.prf_ban .box_flx h4 {
  color: #060a23;
  font-size: 28px;
}

.activities .outbox_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(29, 29, 31, 0.3);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.activities .box_flx {
  display: flex;
}

.activities .box_flx .img_box {
  margin-right: 30px;
}

.activities .box_flx .img_box img {
  height: 103px;
  width: 103px;
  cursor: default;
}

.activities img {
  cursor: pointer;
}

.activities .box_flx span {
  color: rgba(29, 29, 31, 0.6);
  font-family: Sk-Modernist;
  font-size: 15px;
}

.activities .box_flx span .name {
  color: rgba(29, 29, 31, 0.8);
}

.activities .box_flx span .addr {
  color: rgba(29, 29, 31, 0.6);
  text-decoration: underline;
  margin: 0 4px;
}

.swap .swap_pad {
  padding-bottom: 130px;
}

.swap .h_option {
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.swap .popular_nft .box .round_dot {
  width: 25px;
  height: 25px;
  border: 1px solid #1d1d1f;
  border-radius: 50%;
  margin-left: auto;
  margin-top: 15px;
}

.swap .popular_nft .box.active {
  background: #0b081b;
}

.swap .popular_nft .box.active span {
  color: #fff;
}

.swap .popular_nft .box.active .blw_box h5 {
  color: #fff;
}

.swap .popular_nft .box.active p,
.swap .popular_nft .box.active h6 {
  color: #fff;
}

.swap .popular_nft .box.active .round_dot {
  border: 1px solid #fff;
}

.complete_swap {
  border-top: 1px solid #060a23;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: #fff;
}

.complete_swap button {
  margin: 30px 0;
}

.nft {
  padding-bottom: 100px;
}

.nft .nft_box {
  width: 600px;
  margin: 0 auto 50px;
  text-align: center;
}

.nft h2 {
  font-size: 40px;
  margin-bottom: 10px;
}

.nft .des {
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 22px;
}

.nft .nft_box .nft_img {
  margin-bottom: 30px;
  position: relative;
}
.nft .nft_box .nft_img button {
  display: none;
  position: absolute;
  top: 30px;
  right: 30px;
  background: #f9f9f9;
  color: #000;
}
.nft .nft_box .nft_img .fullscreen-enabled button {
  display: block;
}
.nft .nft_box .nft_img button:hover {
  background: #060a23;
  color: #fff !important;
}
.nft .nft_box .nft_img .fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #020202;
}
.nft .nft_box .nft_img img {
  height: 600px;
}

.nft .nft_box .nft_img .fullscrn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  height: 30px;
  cursor: pointer;
}

.nft .nft_box .nft-detail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nft .nft_box .nft-detail p {
  font-size: 20px;
  margin-bottom: 0;
}

.nft .nft_box .nft-detail p a {
  text-decoration: underline;
}

.nft .nft_box .nft-detail img {
  width: 52px;
  height: 52px;
}

.nft .prf_ban .btn_flx button {
  width: unset;
}

.nft .price_box {
  border: 0.71px solid rgba(29, 29, 31, 0.3);
  padding: 25px;
  max-width: 75%;
  margin-left: auto;
  padding: 40px 25px 20px;
}

.nft .price_box .h_flx {
  border-bottom: 1px solid #e6e1e5;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.nft .price_box .h_flx,
.nft .price_box .b_flx > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 1440px) {
  .back {
    display: block !important;
  }
  .nft .price_box .h_flx,
  .nft .price_box .b_flx > div {
    display: inherit;
    justify-content: space-between;
    align-items: center;
  }
}

.nft .price_box .b_flx {
  padding-bottom: 100px;
}

.nft .price_box .b_flx > div {
  padding-bottom: 10px;
}

.nft .price_box .h_flx p {
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 22px;
  margin-bottom: 0;
}

.nft .price_box .h_flx h6 {
  color: #060a23;
  font-family: Clash Display;
  font-size: 24px;
  margin-bottom: 0;
}

.nft .price_box .h_flx h6 span {
  color: rgba(6, 10, 35, 0.5);
  font-family: Clash Display;
  font-size: 22px;
  margin-left: 10px;
}

.nft .price_box .b_flx span {
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 18px;
}

.nft .price_box .b_flx p {
  color: rgba(6, 10, 35, 0.8);
  font-family: Clash Display;
  font-size: 22px;
  margin-bottom: 0;
}

.more_nft {
  background: transparent;
}

.more_nft .box .img_box img {
  height: 300px;
}

.nft .div_flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.nft .div_flx .fl_x {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.nft .div_flx h5 {
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 24px;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
}

.nft .div_flx img.me-3 {
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

.nft .div_flx p {
  color: rgba(29, 29, 31, 0.5);
  font-family: Sk-Modernist;
  margin-bottom: 0;
}

.nft .div_flx .purchased {
  border-radius: 100px;
  border: 0.3px solid #027a48;
  background: #ecfdf3;
  color: #027a48;
  font-family: Sk-Modernist;
  font-size: 9px;
  padding: 3px 10px;
  margin-left: 10px;
}

.nft .div_flx h6 {
  color: rgba(29, 29, 31, 0.7);
  font-family: Sk-Modernist;
  font-size: 20px;
}

.nft .div_flx h6 span {
  color: #1d1d1f;
}

.nft .div_flx button {
  padding: 7px 15px;
}

.nft .div_flx .d-flex {
  align-items: flex-start;
  width: 100%;
}

.nft .prf_ban .details h5 {
  color: #060a23;
  font-family: Clash Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
}

.nft .prf_ban .details .flx {
  display: flex;
  align-items: center;
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 16px;
  margin-bottom: 30px;
}

.nft .prf_ban .details .flx p {
  margin-bottom: 0;
}

.nft .prf_ban .details .flx .arw {
  background: rgba(29, 29, 31, 0.4);
  height: 20px;
  width: 1px;
  margin: 0 20px;
}

.placebid_modal .modal-content {
  border-radius: 0;
  padding: 40px 30px;
}

.placebid_modal h4 {
  font-size: 32px;
  font-weight: 500;
}

.placebid_modal h4 span {
  color: rgba(29, 29, 31, 0.7);
}

.placebid_modal h3 {
  font-size: 28px;
}

.placebid_modal .create_nft .create_nftbox .flx_box {
  margin: 0 19px;
}

.placebid_modal .dropdown button {
  box-shadow: none !important;
  height: 55px;
  width: 100%;
  border: 1px solid rgba(29, 29, 31, 0.3) !important;
  background: #f7f9fc !important;
  color: rgba(29, 29, 31, 0.5) !important;
  font-family: Sk-Modernist;
  border-radius: 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.placebid_modal .dropdown button::after {
  background: url("./assets/images/dropdown_arw.svg");
  width: 12px;
  height: 7px;
  border: 0;
}

.placebid_modal .dropdown button + div {
  width: 101%;
  border-radius: 0;
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 18px;
  z-index: 1;
  margin-left: -2px;
  padding: 0 10px;
}

.create_nft .dropdown button {
  box-shadow: none !important;
  height: 55px;
  width: 100%;
  border: 1px solid rgba(29, 29, 31, 0.3) !important;
  background: #f7f9fc !important;
  color: rgba(29, 29, 31, 0.5) !important;
  font-family: Sk-Modernist;
  border-radius: 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.create_nft .dropdown button::after {
  background: url("./assets/images/dropdown_arw.svg");
  width: 12px;
  height: 7px;
  border: 0;
}

.create_nft .dropdown button + div {
  width: 101%;
  border-radius: 0;
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 18px;
  z-index: 1;
  margin-left: -2px;
  padding: 0 10px;
}

.placebid_modal .dropdown .dropdown-item {
  border-bottom: 1px solid #d6d8db;
  padding: 10px;
  text-decoration: none;
  background: #fff !important;
  color: #1d1d1f !important;
}

.placebid_modal .dropdown .dropdown-item:nth-last-child(1) {
  border-bottom: 0;
}

.placebid_modal .nft .div_flx {
  border-bottom: 1px solid rgba(29, 29, 31, 0.3);
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.placebid_modal .nft .div_flx h6 {
  word-break: break-word;
}

.placebid_modal .tabmodal .nav-link {
  border: 0 !important;
  font-size: 20px;
  color: #1d1d1f;
}

.placebid_modal .tabmodal .nav-link.active {
  border-bottom: 1px solid #060a23 !important;
  color: #060a23 !important;
}

.placebid_modal .tabmodal label {
  font-size: 20px;
  color: #1d1d1f;
}

.placebid_modal .tabmodal .nav {
  width: fit-content;
  justify-content: center;
  margin: 0 auto 20px;
  padding: 0;
}

.placebid_modal .swap_modal .nft .div_flx {
  border: 0;
}

.placebid_modal .swap_modal .nft .div_flx h6 {
  font-size: 24px;
  margin-bottom: 0;
}

.placebid_modal .swap_modal .nft .div_flx .fl_x {
  border-bottom: 1px solid rgba(29, 29, 31, 0.3);
  padding-bottom: 20px;
}

.placebid_modal .swap_modal .nft .div_flx .fl_x > div:nth-child(2) {
  margin: 0;
}

.placebid_modal .swap_modal .form-check-input {
  border: 1px solid rgba(29, 29, 31, 0.7);
  width: 26px;
  height: 26px;
  border-radius: 0;
  box-shadow: none !important;
  cursor: pointer;
}

.placebid_modal .swap_modal .nft {
  height: calc(100vh - 240px);
  overflow-y: scroll;
  padding-right: 5px !important;
}

.placebid_modal .swap_modal .modal-content {
  min-height: 100vh;
}

.sidebar {
  border: 1px solid rgba(29, 29, 31, 0.3);
  background: #f7f9fc;
  padding: 25px 30px;
}

.sidebar h6 {
  color: #060a23;
  font-family: Clash Display;
  font-size: 20px;
  margin-bottom: 30px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 150px;
}

.sidebar li {
  border-bottom: 1px solid rgba(29, 29, 31, 0.6);
}

.sidebar li a {
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 20px;
  text-decoration: none;
  display: block;
  padding: 12px 0;
}

.sidebar li a:focus,
.sidebar li a:hover,
.sidebar li a.active {
  color: #060a23;
}

.sidebar .logout {
  color: #b70808;
  font-family: Sk-Modernist;
  font-size: 24px;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
}
.sidebar .logout:hover img {
  filter: invert(1) brightness(0);
}
.sidebar .logout:hover {
  color: #1d1d1f;
  transition: 0.2s;
}
.right_box {
  padding-left: 50px;
}

.right_box .prf_ban .prf_banimg {
  height: 214px;
  width: 100%;
}

.right_box .prf_ban .img {
  position: relative;
}

.right_box .prf_ban .camera {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}

.right_box .prf_ban .prf_ban_user {
  margin-left: 70px;
  z-index: 1;
  position: relative;
}

.right_box .prf_ban .prf_ban_user img {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
}

.right_box .create_nft.half_side {
  padding-right: 100px;
}

.right_box .create_nft p {
  margin-bottom: 15px;
  color: rgba(29, 29, 31, 0.8);
}

.right_box .create_nft label {
  color: #1d1d1f;
  font-size: 20px;
}

.right_box .create_nft .label_flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right_box .create_nft .label_flx .connected {
  border-radius: 50px;
  border: 0.3px solid #027a48;
  background: #ecfdf3;
  color: #027a48;
  font-family: Sk-Modernist;
  font-size: 9px;
  padding: 0px 5px;
}

.right_box .create_nft .label_flx .connected.red {
  color: #7a0202;
  border: 0.3px solid #7a0202;
  background: #fdecec;
}

.right_box .create_nft .two_grp .label_flx {
  border: 1px solid #b5b7b9 !important;
  border-right: 0 !important;
}

.right_box .create_nft .two_grp input {
  border-left: 0 !important;
}

.right_box .create_nft .two_grp .left_gp {
  border: 1px solid rgba(29, 29, 31, 0.3) !important;
  border-right: 0 !important;
}

.right_box .create_nft .two_grp .meta {
  height: 15px;
}
.right_box .create_nft .two_grp .copy {
  cursor: pointer;
}
.right_box input.bdr {
  border-right: 1px solid rgba(29, 29, 31, 0.3) !important;
  margin-right: 15px;
}

.help {
  padding-bottom: 100px;
}

.help .box {
  color: #1d1d1f;
  font-family: Sk-Modernist;
  font-size: 16px;
  border: 1px solid rgba(29, 29, 31, 0.5);
  background: #f7f9fc;
  margin-bottom: 25px;
  padding: 15px 20px;
  padding-right: 30px;
  width: 100%;
  text-decoration: none;
  display: block;
  position: relative;
}

.help .box::before {
  content: "";
  position: absolute;
  background: url("./assets/images/dropdown_arw.svg") no-repeat center;
  right: 15px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 12px;
  height: 7px;
}

.chat {
  overflow-x: hidden;
  position: relative;
}

.chat .chat_flx {
  display: flex;
  min-height: 100vh;
}

.chat .msg {
  border: 1px solid rgba(29, 29, 31, 0.3);
  border-top: 0;
  border-bottom: 0;
  background: #f7f9fc;
  padding: 25px;
  width: 25%;
}

.chat .msg h5 {
  color: #060a23;
  font-family: Clash Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 30px;
}

.chat .msg .box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(29, 29, 31, 0.2);
  padding: 7px 2px;
  margin: 6px 0;
  cursor: pointer;
}
.chat .msg .box:hover {
  background: #d9d9d9;
}
.chat .msg .selected {
  background: #d9d9d9;
}
li.sended {
  margin-bottom: 8px;
}

.chat .msg .box .us_r {
  width: 44px;
  height: 44px;
}

.chat .msg .box h5 {
  color: rgba(6, 10, 35, 0.8);
  font-family: Sk-Modernist;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 400;
}

.chat .msg .box p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Sk-Modernist;
  margin-bottom: 0;
}

.chat .msg .box p span {
  color: rgba(29, 29, 31, 0.6);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 12px;
  width: 170px;
}

.chat .chat_box {
  width: 50%;
}

.chat .msg .box p span.time {
  color: rgba(6, 10, 35, 0.5);
  font-size: 10px;
  width: 25%;
}

.chat .chat_box .chat_abv {
  width: 100%;
  margin-top: 30px;
  border-bottom: 1px solid rgba(29, 29, 31, 0.3);
}

.chat .chat_box .chat_abv .btn-group {
  margin-left: auto;
  width: fit-content;
  display: flex;
  margin-top: -30px;
}

.chat .chat_box .chat_abv .dropdown-toggle {
  background: transparent;
  padding: 0;
  border: 0;
}
.chat .chat_box .chat_abv .dropdown-toggle + ul a {
  text-decoration: none;
  background: #fff !important;
  color: #1d1d1f !important;
}
.chat .chat_box .back {
  color: rgba(29, 29, 31, 0.8);
  font-family: Sk-Modernist;
  font-size: 16px;
  width: 100%;
  margin-left: 30px;
  margin-bottom: -20px;
}

.chat .chat_box .back img {
  cursor: pointer;
}

.chat .chat_box .userprf {
  white-space: nowrap;
  text-align: center;
  width: 100%;
}

.chat .chat_box .userprf img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.chat .chat_box .userprf h5 {
  color: #060a23;
  font-family: Clash Display;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 12px;
}

.chat .chat_box .userprf span {
  color: rgba(29, 29, 31, 0.6);
  font-family: Sk-Modernist;
  font-size: 14px;
}

.chat .swaps {
  height: 400px;
  border: 1px solid rgba(29, 29, 31, 0.3);
  border-top: 0;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.chat .chat_blw {
  max-width: 80%;
  margin: 0 auto 15px;
}

.chat .chat_blw .input-group {
  background: #fff;
}

.chat .chat_blw input {
  border: 1px solid rgba(29, 29, 31, 0.3) !important;
  background: #f7f9fc;
}

.chat .chat_box .chats {
  padding: 30px;
  padding-bottom: 20px;
  height: 500px;
  overflow-y: scroll;
}

.chat .chat_box .chats ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.chat .chat_box .chats li {
  display: flex;
  align-items: flex-end;
  width: 45%;
  word-break: break-word;
}

.chat .chat_box .chats li.recieved {
  margin: 8px 0;
  margin-left: auto;
  flex-direction: row-reverse;
}
/* li.recieved p {
  background: #b2ffde !important;
} */
.chat .chat_box .chats li.recieved .user {
  margin-right: 0;
  margin-left: 15px;
}
.chat .chat_box .chats li.recieved p {
  border-radius: 8px 8px 0px 8px;
}
.chat .chat_box .chats li p {
  border-radius: 8px 8px 8px 0px;
  background: #ededed;
  padding: 17px 16px;
  color: rgba(29, 29, 31, 0.9);
  font-family: Sk-Modernist;
  font-size: 14px;
  margin-bottom: 0;
}

.chat .chat_box .chats .user {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 15px;
}

.noswaps {
  color: var(--secondary-color, #1d1d1f);
  text-align: center;
  font-family: Sk-Modernist;
  font-size: 14px;
}
.choosefile {
  position: relative;
}
.choosefile input {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-top: -100px;
  opacity: 0;
  cursor: pointer;
}
.choose {
  position: relative;
}
.choose input {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

@media only screen and (max-width: 1399px) {
  .main_navbar .nav_left > div {
    margin-right: 15px !important;
  }
  .chat .msg .box p span {
    width: 127px;
  }

  .nft .div_flx h6 {
    font-size: 18px;
  }

  .nft .div_flx h5 {
    font-size: 18px;
  }

  .nft .div_flx p {
    font-size: 16px;
  }

  .nft .nft_box .nft_img img {
    height: 500px;
  }

  .nft .nft_box {
    width: 500px;
  }

  .explore .popular_nft .col-lg-3 {
    width: 33.3333%;
  }

  .popular_nft .box p,
  .popular_nft .box h6 {
    font-size: 20px;
  }

  .popular_nft .popular_nft_scroll {
    overflow-x: scroll;
    padding-bottom: 10px;
  }

  .popular_nft .row {
    flex-wrap: nowrap;
  }

  .popular_nft .col-lg-4 {
    width: 440px;
  }

  .recent_swap .boxes {
    overflow-x: scroll;
    padding-bottom: 10px;
  }

  .recent_swap .rw_flx div:nth-child(2),
  .recent_swap .rw_flx div:nth-child(5) {
    width: 270px;
  }

  .create_nft .form-switch p {
    font-size: 18px;
  }

  .create_nft .form-switch {
    padding: 15px 10px !important;
    padding-right: 60px !important;
  }

  .trending .trending_scroll {
    overflow-x: scroll;
    padding-bottom: 10px;
  }

  .trending .trending_scroll .row {
    flex-wrap: nowrap;
  }

  .trending .trending_scroll .col-md-3 {
    width: 300px;
  }

  .trending .box .img_box img {
    height: 260px !important;
  }

  .trending .user_flx {
    overflow-x: scroll;
    padding-bottom: 10px;
  }

  .trending .user_flx .user {
    min-width: fit-content;
  }

  .slide .slick-prev:before {
    left: -158px;
  }

  .slide .slick-next:before {
    right: -158px;
  }
  .slidechg .box > div {
    max-width: 70%;
  }
  .footer {
    background-size: unset;
  }
}

@media only screen and (max-width: 1199px) {
  .mob_view {
    display: block !important;
  }

  .pc_view {
    display: none !important;
  }
  .slidechg .slick-slider {
    max-width: 940px;
    margin: 0 auto;
  }
  .slidechg .box > div {
    max-width: 100%;
    border: 0;
  }
  .slidechg .slick-next:before {
    right: 20px !important;
  }
  .slidechg .slick-prev:before {
    left: 20px !important;
  }
  .slidechg .box::before,
  .slidechg .box::after {
    display: none;
  }
  .main_navbar .profile_mob {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    min-height: 90vh;
  }
  /* .main_navbar .nav_left .box{
    border: 0;
  }
  .main_navbar .nav_left .box .fle_x.bdr {
    border: 0;
  } */
  .main_navbar .nav_left .dropdown-item {
    width: fit-content;
  }
  .main_navbar .nav_left .logout a {
    color: #b70808 !important;
    font-family: Sk-Modernist;
  }
  .main_navbar .nav_left .blw_box {
    padding: 20px 0;
  }
  .chat .msg {
    margin-top: 85px;
    margin-bottom: 30px;
    border: 1px solid rgba(29, 29, 31, 0.3);
    width: 100%;
  }

  .chat .swaps {
    display: none;
    width: 100%;
  }

  .chat .chat_box {
    width: 100%;
    position: absolute;
    top: 50px;
    left: 100%;
    z-index: 1;
    background-color: #fff;
    transition: 0.2s;
    bottom: 0;
    overflow: hidden;
  }

  .chat .chat_box.show {
    left: 0;
  }
  .chat .chat_box.show .chat_blw {
    position: fixed;
  }
  .chat .chat_blw {
    background: #fff;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    max-width: 90%;
  }
  .chat .chat_box .chats {
    height: calc(100% - 70px);
  }
  .chat .chat_box .chats li:nth-last-child(1) {
    padding-bottom: 190px;
  }
  .main_navbar .search:hover .input-group-text {
    border-radius: 100px !important;
  }

  .main_navbar .search input {
    width: 0px;
    position: absolute;
    right: -450px;
    bottom: -115%;
    transition: 0.5s;
  }

  .main_navbar .search.show input {
    position: absolute;
    bottom: -115%;
    width: 270px;
    right: -80px;
    border-radius: 0px !important;
    padding: 12px;
  }

  .main_navbar .search input::placeholder {
    padding: 0;
  }

  img.brand_logo {
    display: block;
    width: 70px;
  }

  .main_navbar {
    background: #f0f0f0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .main_navbar .search_pc {
    display: none;
  }

  .main_navbar .search_mob {
    display: inline-flex;
  }

  .main_navbar .offcanvas {
    min-height: 100vh;
  }

  .main_navbar .navbar_right ul {
    flex-direction: column;
  }

  .main_navbar .navbar_right ul li {
    margin-bottom: 10px;
  }

  .main_navbar .navbar_right {
    flex-direction: column;
  }

  .main_navbar .nav_left {
    flex-direction: column;
  }

  .main_navbar .navbar_right ul li {
    margin-right: 0;
  }

  .main_navbar .nav_left > div {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .main_navbar .navbar_common {
    flex-direction: column;
  }

  .desktoplogo {
    display: none;
  }

  .page_header {
    padding-top: 130px;
    background-size: unset;
  }

  .page_header h1 {
    font-size: 50px;
  }

  .h2tag {
    font-size: 35px;
  }

  .popular_nft .blw_box h5 {
    font-size: 24px;
  }

  .popular_nft .box .img_box img {
    height: 365px;
  }

  .slide .slidebg {
    max-width: 100%;
    padding: 0;
  }

  .slide .box .flx {
    margin-bottom: 100px;
  }

  .slide .slidebg_out::before,
  .slide .slidebg_out::after {
    display: none;
  }

  .slide .slick-prev,
  .slide .slick-next {
    z-index: 1;
  }

  .slide .slick-prev:before {
    left: 23px;
  }

  .slide .slick-next:before {
    right: 23px;
  }

  .slide .slick-prev {
    left: 0px;
  }

  .slide .slick-next {
    right: 0px;
  }

  .slide .slick-prev:before,
  .slide .slick-next:before {
    width: 50px;
    height: 50px;
    background-size: contain;
  }

  .slide .box h3 {
    font-size: 28px;
  }

  .footer h6 {
    font-size: 16px;
  }

  .footer {
    font-size: 22px;
  }

  .footer .footer_bottom {
    font-size: 18px;
  }

  .footer .maxbox {
    max-width: 100%;
  }

  .sidebar li a {
    font-size: 18px;
  }

  .nft .div_flx .fl_x {
    flex-direction: column;
    align-items: flex-start;
  }

  .nft .nft_box .nft_img img {
    height: 400px;
  }

  .nft .div_flx button {
    font-size: 16px;
  }

  .nft .div_flx .fl_x div:nth-child(2) {
    margin-top: 10px;
  }

  .nft .nft_box {
    width: 400px;
  }

  .nft .des {
    font-size: 20px;
  }

  .nft .div_flx .fl_x + div {
    margin-left: 40px;
    margin-top: 5px;
  }

  .nft .div_flx {
    flex-direction: column;
    align-items: flex-start;
  }

  .prf_ban .prf_banimg {
    height: 321px;
  }

  .prf_ban .box_flx {
    width: 100%;
    margin-bottom: 30px;
  }

  .nft h2 {
    font-size: 30px;
  }

  .nft .nft_box .nft-detail img {
    width: 42px;
    height: 42px;
  }

  .nft .price_box {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .right_box .create_nft.half_side {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .mob_hide {
    display: none !important;
  }

  .head {
    max-width: 100%;
  }
  .sidebar {
    padding: 15px;
  }
  .sidebar h6 {
    margin-bottom: 5px;
  }
  .sidebar ul {
    margin-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  .sidebar li {
    border: 0;
    margin: 0 10px;
  }
  .sidebar li:nth-child(1) {
    margin-left: 0;
  }
  .sidebar .logout {
    font-size: 16px;
  }
  .right_box {
    padding-left: 0;
    margin-top: 20px;
  }

  .explore .tab-content .h_option {
    margin-top: 20px !important;
  }

  .explore .tab-content .recent_swap {
    padding-top: 32px !important;
    margin-top: 0 !important;
  }

  .explore .overflow {
    overflow-x: scroll;
  }

  .explore .nav {
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 0;
    margin: 0;
  }

  .connect_wallet .leftimg {
    display: none;
  }

  .connect_wallet .connect_wallet_button {
    justify-content: center;
  }

  .connect_wallet .connect_wallet_button.flx {
    justify-content: center;
    position: relative;
  }

  .connect_wallet .connect_wallet_button p {
    position: absolute;
    right: 10px;
  }

  .footer .footer_bottom {
    font-size: 16px;
  }

  .footer .footer_bottom li {
    margin: 0 10px;
  }

  .slide .box .flx {
    flex-direction: column;
    align-items: flex-start;
  }

  .slide .box button {
    margin-top: 10px;
  }

  .slide .slick-prev,
  .slide .slick-next {
    top: 55%;
  }
  .slidechg .slick-prev,
  .slidechg .slick-next {
    top: 55% !important;
  }
  .slidechg .slick-slider {
    max-width: 690px;
  }
  .create_nft .prv_outbox {
    display: none;
  }

  .explore .popular_nft .col-lg-3 {
    width: 50%;
  }

  .explore .h_option {
    flex-direction: column;
    align-items: flex-start;
  }

  .explore .h_option > div {
    margin: 0 0 10px !important;
  }

  .create_box label {
    width: 300px;
  }

  .nft .row {
    flex-direction: column-reverse;
  }

  .nft {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .h2tag {
    font-size: 32px;
  }
  .explore .tab-pane section,
  .explore .tab-content .recent_swap {
    padding-top: 30px !important;
  }
  .recent_swap table th {
    padding-top: 0;
  }
  .chat .chat_box .chats li {
    width: 75%;
  }

  .right_box .flex_align {
    flex-direction: column;
  }

  .right_box .flex_align input {
    width: 100%;
    margin: 0 !important;
    margin-bottom: 15px !important;
  }

  .sidebar li a {
    font-size: 16px;
  }

  .placebid_modal h4,
  .placebid_modal h3 {
    font-size: 24px;
  }

  .placebid_modal .primary_btn {
    font-size: 16px;
  }
  .nft .nft_box .nft_img button {
    font-size: 15px;
    padding: 3px 15px;
  }
  .nft .nft_box .nft_img img {
    height: 350px;
  }

  .nft .nft_box {
    width: 350px;
  }

  .swap .h_option {
    max-width: 100%;
  }

  .prf_ban .btn_flx .msg {
    display: block;
  }

  .prf_ban .social_mob {
    display: block !important;
  }

  .prf_ban .box_flx h4 {
    font-size: 20px;
  }

  .page_header {
    text-align: center;
    padding-bottom: 40px;
  }
  .page_header .row {
    min-height: calc(90vh - 200px);
  }
  .page_header h1 {
    font-size: 38px;
  }

  .page_header .img_box {
    display: none;
  }

  .slide .box {
    padding: 15px;
  }

  .slide .box button {
    padding: 7px 15px;
    border-radius: 70px;
    font-size: 16px;
    min-width: 111px;
  }

  .slide .box h3 {
    font-size: 22px;
  }

  .slide .box h3 {
    max-width: 100%;
  }
  .slidechg .slick-slider {
    max-width: 510px;
  }
  .footer {
    background-size: cover;
  }

  .footer .footer_panel {
    padding-bottom: 30px;
  }

  .footer .footer_panel ul {
    margin-bottom: 35px;
  }

  .footer .footer_bottom ul {
    flex-wrap: wrap;
  }

  .footer .footer_bottom li {
    width: 46%;
    margin: 0 5px 7px;
  }

  .footer .footer_panel .para {
    font-size: 14px;
    margin-bottom: 35px;
  }

  .footer ul {
    font-size: 20px;
  }

  .footer .footer_bottom li {
    font-size: 16px;
  }

  .footer .flogo {
    display: none;
  }

  .footer .input-group {
    flex-direction: column;
  }

  .footer .input-group input {
    width: 100%;
    margin: 0 0 15px;
    height: 48px;
  }

  .footer .flx {
    flex-direction: column-reverse;
  }

  .create_box label {
    width: 210px;
    height: 230px;
    font-size: 28px;
  }

  .create_box label .best {
    display: none;
  }

  .create_box label .nft {
    display: none;
  }

  .wizard-progress .step {
    font-size: 16px;
  }

  .popular_nft .col-lg-4 {
    width: 300px;
  }

  .popular_nft .blw_box h5 {
    font-size: 20px;
  }

  .popular_nft .box p,
  .popular_nft .box h6 {
    font-size: 18px;
  }

  .popular_nft .box .img_box img {
    height: 265px;
  }

  .explore .popular_nft .col-lg-3 {
    width: 100%;
  }

  .explore .popular_nft .box {
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 60%;
  }
}

@media only screen and (max-width: 575px) {
  .choosefile input {
    width: 80px;
    height: 80px;
    margin-top: -40px;
  }
  .para {
    font-size: 14px;
  }
  .recent_swap table th {
    font-size: 14px;
    padding-bottom: 15px;
  }
  .recent_swap table tr:nth-child(1) td {
    padding-top: 12px;
  }
  .recent_swap table tr:nth-last-child(1) td {
    padding-bottom: 12px;
  }
  .recent_swap table td:nth-child(1),
  .recent_swap table th:nth-child(1) {
    padding-left: 15px;
  }
  .recent_swap table td .user_mini {
    border-radius: 4px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
  .recent_swap table {
    font-size: 14px;
  }
  .recent_swap .rw_flx div {
    margin-right: 10px;
  }
  .recent_swap .boxes .rw_flx {
    font-size: 16px;
  }
  .recent_swap .boxes .rw_flx.head_flx {
    font-size: 16px;
  }
  .recent_swap .bdr {
    padding: 15px 25px;
  }
  .recent_swap .boxes .rw_flx.head_flx {
    padding: 0 22px;
  }
  .recent_swap .user {
    width: 40px;
    height: 40px;
  }
  .chat .chat_box .chats .user {
    margin-right: 5px;
  }
  .chat .chat_box .chats li.recieved .user {
    margin-left: 5px;
  }
  .chat .chat_box .chats {
    padding: 15px;
  }
  .right_box .prf_ban .prf_ban_user {
    margin-left: 30px;
  }

  .placebid_modal .dropdown .dropdown-item {
    font-size: 16px;
  }

  .placebid_modal .create_nft .create_nftbox .flx_box {
    margin: 0 10px;
  }

  .placebid_modal .swap_modal .nft .div_flx h6 {
    font-size: 20px;
  }

  .placebid_modal .swap_modal .form-check-input {
    width: 18px;
    height: 18px;
  }

  .placebid_modal .modal-content {
    padding: 5px 0;
  }

  .nft .div_flx .primary_btn {
    font-size: 16px;
  }

  .nft .div_flx h6 {
    font-size: 16px;
  }

  .nft .div_flx h5 {
    font-size: 16px;
  }

  .nft .div_flx p {
    font-size: 14px;
  }

  .nft .div_flx .fl_x div:nth-child(2) h5 {
    font-size: 20px;
  }

  .nft .div_flx .fl_x div:nth-child(2) p {
    font-size: 16px;
  }

  .nft .div_flx .d-flex img {
    width: 25px;
    height: 25px;
  }

  .nft .div_flx .d-flex button img {
    width: unset;
    height: unset;
  }

  .nft .div_flx .purchased {
    display: none;
  }

  .nft .prf_ban .details h5 {
    font-size: 16px;
  }

  .nft .prf_ban .details .flx .arw {
    margin: 0 5px;
  }

  .nft .prf_ban .details .flx img {
    margin: 3px !important;
  }

  .nft .prf_ban .details .flx {
    font-size: 14px;
  }

  .nft h2 {
    font-size: 24px;
  }

  .nft .nft_box .nft-detail p {
    font-size: 14px;
  }

  .nft .nft_box .nft-detail img {
    width: 32px;
    height: 32px;
  }

  .nft .des {
    font-size: 14px;
  }

  .nft .price_box .b_flx span,
  .nft .price_box .h_flx p {
    font-size: 16px;
  }

  .nft .price_box .b_flx p,
  .nft .price_box .h_flx h6 {
    font-size: 20px;
  }

  .prf_ban_collect .box_flx {
    align-items: flex-start;
  }

  .prf_ban_collect .box_flx .box {
    padding: 0 !important;
    padding: 0 7px !important;
  }

  .prf_ban_collect .box_flx .box::before {
    height: 30px;
    top: 10px;
    margin: unset;
  }

  .prf_ban .prf_banimg {
    height: 280px;
  }

  .prf_ban .prf_ban_user img {
    width: 80px;
    height: 80px;
    border: 5px solid #fff;
    margin-top: -40px;
  }

  .prf_ban .btn_flx .addr {
    padding: 10px 12px;
  }

  .prf_ban .btn_flx {
    margin-bottom: 20px;
  }

  .prf_ban .para {
    margin-bottom: 0 !important;
  }

  .prf_ban .box_flx {
    margin-bottom: 20px;
  }

  .prf_ban .box_flx h4 {
    font-size: 16px;
  }

  .prf_ban .btn_flx button {
    font-size: 14px;
    margin-right: 10px;
    width: unset;
    padding: 10px 15px;
  }

  .prf_ban .box_flx p {
    font-size: 14px;
  }

  .prf_ban .para {
    font-size: 14px;
  }

  .prf_ban .social a {
    width: 33px;
    height: 33px;
  }

  .prf_detail .h2tag {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .main_navbar .search:hover input {
    width: 265px;
  }

  .activities .box_flx {
    flex-direction: column;
  }

  .activities .box_flx .img_box {
    margin-bottom: 15px;
  }

  .create_box label {
    width: 130px;
    height: 130px;
    font-size: 20px;
  }

  .create_box label svg {
    display: none;
  }

  .create_box .para {
    font-size: 14px;
  }

  .create_box .flx_box {
    margin: 0 10px;
  }

  .create_nft p {
    font-size: 14px;
  }

  .create_nft .price_box .fl_x {
    font-size: 14px;
  }

  .create_nft .form-control {
    height: 48px;
  }

  .create_nft .choose_box {
    font-size: 12px;
  }

  .create_nft .create_nftbox .flx_box {
    margin: 0 10px;
  }

  .create_nft label {
    font-size: 16px;
  }

  .create_nft span.small {
    font-size: 12px;
  }

  .create_nft .h2tag,
  .create_nft .para {
    text-align: center;
  }

  .create_nft .para {
    font-size: 14px;
  }

  .create_nft .form-switch p {
    font-size: 14px;
  }

  .create_box label p {
    font-size: 13px;
  }

  .connect_wallet h5 {
    font-size: 16px;
  }

  .wizard-progress .step {
    font-size: 12px;
  }

  .slide .box h3 {
    font-size: 20px;
  }
  .slidechg .slick-next:before {
    right: 30px !important;
  }
  .slidechg .slick-prev:before {
    left: 30px !important;
  }
  .slidechg .slick-slider {
    max-width: 100%;
    padding: 0 15px;
  }
  .connect_wallet .connect_wallet_button p {
    font-size: 10px;
  }

  .connect_wallet .connect_wallet_button span {
    font-size: 16px;
  }

  .connect_wallet .para {
    font-size: 14px;
  }

  .create_nft .create_nftbox label {
    font-size: 16px;
    height: 130px;
  }

  .popular_nft .col-lg-4 {
    max-width: 250px;
  }

  .popular_nft .col-md-3 {
    max-width: 270px;
  }

  .popular_nft .primary_btn {
    margin-top: 20px;
  }

  .popular_nft .box span {
    font-size: 16px;
  }

  .popular_nft .box p {
    font-size: 14px;
  }

  .popular_nft .box h6 {
    font-size: 14px;
  }

  .explore .nav-link {
    font-size: 16px;
  }

  .explore .h_option .sel select {
    font-size: 14px;
  }

  .explore .h_option ul {
    font-size: 14px;
  }

  .explore .box .blw_ctn h6 {
    font-size: 18px;
  }

  .explore .popular_nftuser .blw_ctn p,
  .popular_nft .box h6,
  .popular_nft .box span {
    font-size: 14px;
  }

  .explore .popular_nftuser .blw_ctnbox span {
    font-size: 16px;
  }

  .popular_nft .box .img_box img,
  .explore .popular_nft .box .img_box img {
    height: 230px !important;
  }

  .popular_nft .blw_box h5 {
    margin-bottom: 12px;
  }

  .popular_nft .blw_box {
    padding: 20px 15px;
  }

  .popular_nft .blw_box .flx_ctn .mb-3 {
    margin-bottom: 5px !important;
  }

  .prf_ban .btn_flx button {
    margin-bottom: 10px;
  }

  .explore .popular_nft .box {
    max-width: 240px;
  }
}

@media only screen and (max-width: 450px) {
  .back {
    display: block !important;
  }
  .sidebar {
    padding: 15px 10px;
  }
  .sidebar li {
    margin: 0 4px;
  }
  .settingpad {
    padding-top: 90px;
  }
  .nft .nft_box .nft_img img {
    height: 250px;
  }

  .nft .nft_box .nft_img .fullscrn {
    right: 30px;
  }

  .nft .nft_box {
    width: 290px;
  }

  .footer .footer_bottom li {
    font-size: 13px;
  }

  .prf_ban .btn_flx.nowrap {
    flex-direction: row;
  }
  .prf_ban .btn_flx.nowrap .primary_btn {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding: 8px 5px !important;
    font-size: 14px;
    height: 37px;
  }
  .prf_ban .btn_flx {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 400px) {
  .explore .popular_nft .box {
    max-width: 100%;
  }
  .back {
    display: block !important;
  }
  .popular_nft .box .img_box img,
  .explore .popular_nft .box .img_box img {
    height: 200px !important;
  }
}

span.error-msg {
  color: red;
  font-size: 14px;
}

input:disabled {
  opacity: 0.3;
}
.loadBefore::before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(245, 242, 242, 0.5);
  z-index: 1500;
  overflow: hidden;
}
li.imageGrid {
  display: inline-block;
  margin-bottom: 30px;
  margin-right: 10px;
  box-shadow: 2px 2px 6px 0px #6e6a6a7d;
}

.de_countdown span {
  font-weight: 600;
  font-size: 14px !important;
}
.de_countdown {
  border: 1px solid #000;
  position: absolute;
  padding: 8px 20px;
  margin: 10px;
  background: #ffffffb5;
}

ul.dropdown-menu.show.elutha {
  top: 100px;
  left: -126px;
}
ul.dropdown-menu.show.elutha li a {
  padding: 14px 24px;
}
ul.dropdown-menu.show.elutha li:hover a {
  color: #fff !important;
}

ul.dropdown-menu.show.elutha li:hover {
  background: #000;
}

.darked {
  background: #2e2e2e;
}
button:disabled {
  opacity: 0.6;
}

.text-bold11 {
  font-weight: 600;
}
.blue_img {
  filter: blur(2px) !important;
}
.bidTimeCount {
  border: 1px solid #222;
  padding: 5px 18px;
  font-size: 13px;
  font-weight: 600;
  color: #404040;
  margin-left: 10px;
}

.unread-contact {
  font-weight: bold; /* Example styling for unread contacts */
  color: darkgray; /* Example styling for unread contacts */
}

.back {
  display: none;
}
